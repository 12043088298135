import React, { useState, useEffect, useContext } from "react";
import AsyncSelect from 'react-select/async';
import HeaderProperty from "./component/HeaderProperty";
import Footer from "./component/Footer";
import { API_URL } from "../config";
import { useUI } from './component/UIContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt } from "@fortawesome/free-solid-svg-icons";
import Loading from "./component/Loading";
import { SearchResultsContext } from './component/SearchResultsContext';
import SearchResultsDrawer from './component/SearchResultsDrawer';

const Home = () => {
    const [loading, setLoading] = useState(false);
    const { toggleBackdrop } = useUI();
    const { setSearchResults } = useContext(SearchResultsContext);
    const [isScrolled, setIsScrolled] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    
    const handleScroll = () => {
        if (window.scrollY > 50) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const formatOptionLabel = ({ label, value }, { inputValue }) => {
        const regex = new RegExp(`(${inputValue})`, 'gi');
        const parts = label.split(regex);
        return (
            <div>
                {parts.map((part, index) => 
                    regex.test(part) ? <b key={index}>{part}</b> : part
                )}
            </div>
        );
    };

    const loadOptions = async (inputValue, callback) => {
        if (inputValue.length < 2) return [];

        try {
            const response = await fetch(`${API_URL}/search?query=${inputValue}`);
            const data = await response.json();
            const options = data.map(item => ({
                label: item.display,
                value: item.id,
            }));
            callback(options);
        } catch (err) {
            console.error("Error loading options:", err);
            return [];
        }
    };

    const handleInputChange = async (selectedOption) => {
        setLoading(true);
        setSelectedOption(selectedOption);
        if (selectedOption) {
            try {
                const response = await fetch(`${API_URL}/search/match/against?query=${encodeURIComponent(selectedOption.label)}`);
                const data = await response.json();
                setSearchResults(data);
                toggleBackdrop(true);
                setLoading(false);
                setSelectedOption(null); // Clear the search box after selection
            } catch (err) {
                console.error("Error when fetching from the API:", err);
                setLoading(false);
            }
        } else {
            setLoading(false);
        }
    };

    return (
        <div>
            <div className={`property-detail-head${isScrolled ? ' scrolled' : ''}`}>
                <HeaderProperty />
            </div>
            {loading ? <Loading text="" /> : ""}
            <div className="property-detail-head-inner home-search">
                <div className="container">
                    <div className="form-inline row">
                        <div className="col-md-12 col-xs-12">
                            <h2>Find information on property taxes and make tax payments</h2>
                            <AsyncSelect
                                cacheOptions
                                defaultOptions
                                loadOptions={loadOptions}
                                onChange={handleInputChange}
                                formatOptionLabel={formatOptionLabel}
                                placeholder="Property Lookup By Account, Bill Number, Name, Address"
                                className="search-wrap"
                                value={selectedOption} // Bind the value to the state
                            />
                        </div>
                    </div>
                </div>
                <div className="dark"></div>
            </div>
            
            <div className="renewal-form-wrap">
                <div className="renewal-form-inner">
                    <div className="usp-wrap">
                        <div className="margin-auto">
                            <div className="row">
                                <div className="col-md-12">
                                    <ul>
                                        <li>
                                            <FontAwesomeIcon icon={faBolt} /> Simplify Property Tax Management
                                            <br /> with our user-friendly app
                                        </li>
                                        <li>
                                            <FontAwesomeIcon icon={faBolt} /> Never Miss a Deadline
                                            <br /> with timely reminders
                                        </li>
                                        <li>
                                            <FontAwesomeIcon icon={faBolt} /> Secure and Private
                                            <br /> data protection
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container pt-30 pb-30 mkt-wrap">
                <div className="row pt-40 pb-40">
                    <div className="col-md-12 col-sm-12 col-xs-12 mb-40">
                        <h2>
                            <span>Our</span> Offerings
                        </h2>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <div className="description-box">
                            <div className="icon des-icon1">
                                {/* You can place an icon here */}
                            </div>
                            <h3>Property Tax Management Made Easy</h3>
                            <p>
                                Streamline your property tax management process with our user-friendly
                                service. Never miss a tax deadline.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <div className="description-box">
                            <div className="icon des-icon2">
                                {/* You can place a different icon here */}
                            </div>
                            <h3>Automatic Payments!</h3>
                            <p>Never Miss a Tax Payment Again! Stay on top of your property taxes hassle-free with Renew My Taxes</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <div className="description-box">
                            <div className="icon des-icon3">
                                {/* You can place another icon here */}
                            </div>
                            <h3>Dedicated Customer Support</h3>
                            <p>Our dedicated support team is available to assist you. Contact us anytime for expert assistance. Reach out now!</p>
                        </div>
                    </div>
                </div>
            </div>
            <SearchResultsDrawer />
            <Footer />
        </div>
    );
};

export default Home;
